import Image from "next/image";
import Link from "next/link";
import Footerl1 from "../public/Footerl1.webp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Footer = () => {
  return (

<footer className="bg-[#0a3621] text-[#FFFFFF] py-10">
<div className="mx-auto px-4 sm:px-6 lg:px-8">
  <div className="flex flex-col md:flex-row justify-around md:gap-5 space-y-8 md:space-y-0">
    <div className="font-poppins w-[100%]">
      <div className="mb-5 w-[100%]">
        <Image src="/Footerlogo.svg" alt="Mocha Logo" className="w-[145px] h-[46px]  " 
      width={145} height={46} style={{objectFit:"contain"}} />
      <span className="text-[10px] mt-0 font-poppins leading-[14px] font-[400px]">Simple. Secure. Tailored.</span>
      </div>
      
      <p className="max-w-sm text-[20px] font-poppins leading-[28px] "   >
        A dedicated team committed to providing our clients peace of mind with innovative business solutions on a highly secure platform.
      </p>
      <div className="flex space-x-4 mt-4 font-poppins">
        <Link href="https://www.facebook.com/people/Mocha-Accounting/61565755627505/">
          <Image src="/socials/fb.svg" alt="Facebook" className="w-8 h-8" width={32} height={32} />
        </Link>
        <Link href="#">
          <Image src="/socials/twt.svg" alt="Twitter" className="w-8 h-8" width={32} height={32} />
        </Link>
        <Link href="https://www.instagram.com/mochaaccounting/">
          <Image src="/socials/inst.svg" alt="Instagram" className="w-8 h-8" width={32} height={32} />
        </Link>
        <Link href="https://www.linkedin.com/company/mocha-accounting">
          <Image src="/socials/linkedin.svg" alt="LinkedIn" className="w-8 h-8" width={32} height={32} />
        </Link>
        <Link href="#">
          <Image src="/socials/yt.svg" alt="YouTube" className="w-8 h-8" width={32} height={32} />
        </Link>
      </div>
    </div>

  
    <div className="grid grid-cols-1 font-poppins w-[100%] sm:grid-cols-3 gap-8 text-[18px]">
      <div>
        <h4 className="font-semibold text-[20px]  font-poppins mb-2">Product</h4>
        <ul className="space-y-2">
          <li>
            <Link href="/features/invoicing" className="hover:text-[#ffb600]">
              Features
            </Link>
          </li>
          <li>
            <Link href="/pricing" className=" hover:text-[#ffb600]">
              Pricing
            </Link>
          </li>
          <li>
            <Link href="/blogs" className=" hover:text-[#ffb600]">
              Resources
            </Link>
          </li>
        </ul>
      </div>
      <div className="font-poppins">
        <h4 className="font-semibold text-[20px]  font-poppins mb-2">Company</h4>
        <ul className="space-y-2 text-[18px]">
          <li>
            <Link href="/aboutus/ourteam" className=" hover:text-[#ffb600]">
              About
            </Link>
          </li>
          <li>
            <Link href="/aboutus/company" className=" hover:text-[#ffb600]">
              Vision
            </Link>
          </li>
          <li>
            <Link href="/aboutus/company" className=" hover:text-[#ffb600]">
              Our Values
            </Link>
          </li>
          <li>
            <Link href="/contact" className=" hover:text-[#ffb600]">
              Contact Us
            </Link>
          </li>
          <li>
            <Link href="/careers" className="hover:text-[#ffb600]">
              Careers
            </Link>
          </li>
        </ul>
      </div>

      <div className="font-poppins ">
        <h4 className="font-semibold text-[20px]  font-poppins mb-2">Support</h4>
        <ul className="space-y-2 text-[18px]">
          <li>
            <Link href="#" className="hover:text-[#ffb600]">
              Getting Started
            </Link>
          </li>
          <li>
            <Link href="/resources/helpcenter" className=" hover:text-[#ffb600]">
              Help Center
            </Link>
          </li>
          <li>
            <Link href="#" className="hover:text-[#ffb600] ">
              Request Support
            </Link>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div className="border-t border-[#E49B0F] mt-10 pt-6 text-sm text-gray-400 flex flex-col m-auto md:flex-row justify-between">
    <p className="m-auto font-poppins">Copyright © {new Date().getFullYear()} Mocha | All Rights Reserved 
      | <Link href="/term" className="hover:text-[#ffb600]">
        Terms and Conditions
      </Link> | <Link href="/privacy" className="hover:text-[#ffb600]">
        Privacy Policy
      </Link></p>
   
  </div>
</div>
</footer>

  );
};
export default Footer;

